<template>
  <CRow>
    
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>Price</strong>
        </CCardHeader>
        <CCardBody>
          <CForm @submit.prevent="newPrice" id="form">
            <CRow>
              <CCol col=12>
                <CAlert
                  :show.sync="dismissCountDown"
                  closeButton
                  color="danger"
                  fade
                >
                  {{errorMsg}}
                </CAlert>
              </CCol>
              <CCol col=6>
                <CInput
                  label="Dias inicial"
                  :value="this.initalDate"
                  v-model="initalDate"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Dias final"
                  :value="this.finalDate"
                  v-model="finalDate"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Europa"
                  :value="this.moveEurope"
                  v-model="moveEurope"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Resto do Mundo"
                  :value="this.moveWorld"
                  v-model="moveWorld"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Plus Europa"
                  :value="this.movePlusEurope"
                  v-model="movePlusEurope"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Plus Resto do Mundo"
                  :value="this.movePlusWorld"
                  v-model="movePlusWorld"
                  bottom
                  type="number"
                  required
                />
              </CCol>
              

            
              
              <CCol col=12 class="text-center">
                <CButton size="sm" color="warning" @click="goBack()" style="margin-right: 10px;">Back</CButton>
                <CButton type="submit" size="sm" color="success">Save</CButton>
              </CCol>
            </CRow>
            
              
          </CForm>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Prices from "@/services/PriceDataService";
export default {
  name: 'Price',
  data: () => {
    return {
      id: 0,
      dismissCountDown: 0,
      errorMsg: '',
      initalDate: '',
      finalDate: '',
      moveEurope: '',
      moveWorld: '',
      movePlusEurope: '',
      movePlusWorld: ''
    }
  },
  methods: {
    
    goBack() {
      this.$router.push('/prices');
    },
    
    newPrice() {
      Prices.newPrice(this.initalDate, this.finalDate, this.moveEurope, this.moveWorld, this.movePlusEurope, this.movePlusWorld)
      .then(response => {
        let message = {
          status: 'success',
          text: '<strong>Preços adicionados</strong>'
        }
        EventBus.$emit('showMessage', message);
        this.$router.push('/prices');
      })
      .catch(e => {
        alert(e);
      });
      return false
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    
  }
}
</script>
